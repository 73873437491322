import * as THREE from 'three';
import {isArray, isFunction, isIterable, isNumber, isObject} from './helpers/type';

/* Promise */
export class PromiseTimeoutError extends Error {
	constructor() {
		super( 'timeout error' );
		this.name = 'PromiseTimeoutError';
		console.dir(this);
	}
}

Promise.external = function( timeout ) {
	let resolve, reject;

	let promise = new Promise( ( res, rej ) => {
		resolve = res;
		reject = rej;
	} );

	if ( isNumber( timeout ) ) {
		let timer = setTimeout( () => reject( new PromiseTimeoutError() ), timeout );
		promise.finally( () => clearTimeout( timer ) );
	}

	return Object.defineProperties( promise, {
		resolve: { value: resolve	},
		reject: { value: reject },
	} );
};

/* Array */
if ( !isFunction( Array.prototype.toReversed ) ) {
	Array.prototype.toReversed = function toReversed() {
		return [ ...this ].reverse();
	}
}

if ( !isFunction( Array.prototype.toSorted ) ) {
	Array.prototype.toSorted = function toSorted( sortFn ) {
		return [ ...this ].sort( sortFn );
	}
}

/* THREE */
THREE.Vector2.prototype.toFixed = function( fractionDigits ) {
	return this.set( +this.x.toFixed( fractionDigits ), +this.y.toFixed( fractionDigits ) );
}

THREE.Vector3.prototype.toFixed = function( fractionDigits ) {
	return this.set( +this.x.toFixed( fractionDigits ), +this.y.toFixed( fractionDigits ), +this.z.toFixed( fractionDigits ) );
}